import React, { useEffect, useState } from 'react'
import { Button } from 'antd'
import { Color } from '../../Common/Color'
import { useNavigate } from 'react-router'
import { get } from '../../Common/api'
import { useTranslation } from 'react-i18next'

export default function BoxListHeader ({ lat, long }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [localisation, setLocalisation] = useState('XXXXX')

  useEffect(() => {
    get('public/postal_places', { lat: lat || '', long: long || '' }, (json) => {
      setLocalisation(json[0].zip_code)
    })
  }, [lat, long])
  return (
        <div style={{ padding: '0 1em' }}>
            <div className={'resultSentence'} style={{
              backgroundColor: Color.grey,
              borderRadius: '100px',
              padding: '1em',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
                <span style={{ fontWeight: 'bold' }}>{t('Public.BoxList.nearestBox')} <span style={{ color: Color.red }}>{localisation}</span></span>
                <Button onClick={() => navigate('/')} style={{ marginLeft: '1em' }} type="primary">{t('Public.BoxList.change')}</Button>
            </div>
        </div>
  )
}
