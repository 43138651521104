
import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { get } from '../../Common/api'
import dayjs from 'dayjs'

import SlotForm from './SlotForm'
import SlotLayout from './SlotLayout'
import { useSearchParams } from 'react-router-dom'

const toDate = (value) => {
  if (value === 'now') return new Date()
  const date = new Date(dayjs(value).toDate())
  return date
}

export default function CreateSlotPage () {
  const { id, slotDate } = useParams()
  const { t } = useTranslation()
  const [box, setBox] = useState(null)
  const [params, _setParams] = useSearchParams()

  const date = toDate(params.get('date'))
  const isNow = slotDate === 'now'

  useEffect(() => {
    get(`public/boxes/${id}`, {}, (json) => {
      setBox(json)
    })
  }, [id])

  return (
    <SlotLayout date={date} box={box} isNow={isNow} title={t('Public.CreateSlotPage.title')}>
      <SlotForm date={date} box={box} isNow={isNow} />
    </SlotLayout>
  )
}
