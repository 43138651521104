import React, { useEffect, useState } from 'react'
import { Button, DatePicker, Descriptions, Divider, Drawer, Form, Input, Modal, Spin, Switch, Tag } from 'antd'
import { t } from 'i18next'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import OneLineSlot from '../BoxPage/OneLineSlot'
import { useForm } from 'antd/es/form/Form'
import CommentDetail from './CommentDetail'
import { post } from '../../Common/api'

const { TextArea } = Input

export default function EventDetail ({ event, onClose, open, updateEvent, setSelectedEvent }) {
  const [form] = useForm()
  const [asanaForm] = useForm()
  const [comments, setComments] = useState([])
  const [loading, setloading] = useState(false)
  const [displayAsanaForm, setDisplayAsanaForm] = useState(false)

  useEffect(() => {
    setComments(event?.comments)
    form.setFieldsValue(event)
  }, [event])

  const updateTitle = (newTitle) => {
    if (newTitle.target.value !== event.title) {
      event.title = newTitle.target.value
      updateEvent(event)
    }
  }

  const updateDescription = (newDescription) => {
    if (newDescription.target.value !== event.description) {
      event.description = newDescription.target.value
      updateEvent(event)
    }
  }

  const addComment = (comment) => {
    setSelectedEvent({
      ...event,
      comment_count: event.comment_count + 1,
      comments: [comment, ...comments]
    })
  }

  const onClick = () => {
    asanaForm.resetFields()
    setDisplayAsanaForm(true)
  }

  const asana = (event) => {
    if (event.asana_url) {
      return <Descriptions.Item label={t('Admin.EventList.table.asana_url')}><Link target="_blank" to={event.asana_url}>{t('Admin.Event.asana_url')}</Link></Descriptions.Item>
    } else {
      return <Descriptions.Item><Button type='primary' onClick={onClick}>{t('Admin.EventList.table.createAsana')}</Button></Descriptions.Item>
    }
  }

  const saveAsana = (event) => {
    setloading(true)
    post(`admin/events/${event.id}/asana_ticket`, asanaForm.getFieldsValue(), (res) => {
      setSelectedEvent(res)
      setDisplayAsanaForm(false)
      setloading(false)
    }, () => {
      setloading(false)
    })
  }

  return (
    <>
      <Modal
        open={displayAsanaForm}
        width="1000px"
        title={t('Admin.EventList.addAsana')}
        okText={t('actions.validate')}
        onOk={() => saveAsana(event)}
        onCancel={() => setDisplayAsanaForm(false)}
      >
        <Spin spinning={loading}>
          <Form form={asanaForm}>
            <Form.Item name='title' label={t('Admin.EventList.table.asana.title')} rules={[{ required: true }]} >
              <Input />
            </Form.Item>
            <Form.Item name='description' label={t('Admin.EventList.table.asana.description')} rules={[{ required: true }]} >
              <TextArea />
            </Form.Item>
            <Form.Item name='url' label={t('Admin.EventList.table.asana.url')}>
              <Input />
            </Form.Item>
            <Form.Item name='date' label={t('Admin.EventList.table.asana.date')}>
              <DatePicker showTime={{ format: 'HH:mm' }} format="DD/MM/YYYY HH:mm" />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
      <Drawer
        title={
          <Form form={form}>
            <Form.Item style={{ marginBottom: '0px' }} name="title">
              <Input style={{ border: 'none', fontWeight: 'bold' }} onBlur={updateTitle} />
            </Form.Item>
          </Form>}
        placement="right"
        closable={false}
        onClose={onClose}
        open={open}
        size={'large'}
        getContainer={false}
      >
        <Descriptions column={1}>
          <Descriptions.Item label={t('Admin.EventList.table.resolved')}>
            <Form form={form}>
              <Form.Item style={{ marginBottom: '0px' }} name="resolved">
                <Switch onChange={(value) => updateEvent({ ...event, resolved: value })} checked={event.resolved}/>
              </Form.Item>
            </Form>
          </Descriptions.Item>
          <Descriptions.Item label={t('Admin.EventList.table.number')}>{event.number}</Descriptions.Item>
          <Descriptions.Item label={t('Admin.EventList.table.created_at')}>{dayjs(event.created_at).format('DD/MM/YY HH[h]mm')}</Descriptions.Item>
          {event.resolved && event.resolved_at &&
            <Descriptions.Item label={t('Admin.EventList.table.resolved_at')}>{dayjs(event.resolved_at).format('DD/MM/YY HH[h]mm')}</Descriptions.Item>
          }
          {!event.resolved &&
            <Descriptions.Item label={t('Admin.EventList.table.resolved_at')}><Tag color="error">{t('Admin.Event.unresolved')}</Tag></Descriptions.Item>
          }
          { asana(event) }
          {event.box_id && event.box &&
            <Descriptions.Item label={t('Admin.EventList.table.box')}><Link to={`/admin/box/${event.box.id}/calendar`}>{event.box.name}</Link></Descriptions.Item>
          }
          {event.slot_id && event.slot &&
            <Descriptions.Item label={t('Admin.EventList.table.slot')}>
              <OneLineSlot slot={event.slot} onCalendar={false} />
            </Descriptions.Item>
          }
          {event.alert_id && event.alert &&
            <Descriptions.Item label={t('Admin.EventList.table.alert')}>{event.alert.message} <br /> {event.alert.level} </Descriptions.Item>
          }
        </Descriptions>
        <Divider />
        <Descriptions layout="vertical">
          <Descriptions.Item label={t('Admin.EventList.table.description')}>
            <Form form={form} style={{ width: '100%' }}>
              <Form.Item name="description" style={{ width: '100%' }}>
                <TextArea style={{ border: 'none' }} onBlur={updateDescription} autoSize={{ minRows: 8 }} />
              </Form.Item> </Form>
          </Descriptions.Item>
        </Descriptions>
        <Divider />
        {comments && comments.map((comment) => (
          <div key={comment.id}>
            <CommentDetail eventId={event.id} comment={comment} action="update" />
          </div>
        ))}
        <div key={`new-comment-${event.id}`}>
          <CommentDetail eventId={event.id} action="new" comment={{ content: '' }} addComment={addComment} />
        </div>
      </Drawer>
    </>
  )
}
