import { Button, Layout, Menu } from 'antd'
import React, { useContext, useState } from 'react'
import { Link, Outlet, useNavigate } from 'react-router-dom'
import EnsureLogin from '../EnsureLogin'
import { Color } from '../../Common/Color'
import { deleteRequest } from '../../Common/api'
import GlobalContext from '../GlobalContext'
import { t } from 'i18next'

const { Header, Content } = Layout

export default function MenuLayout ({ children }) {
  const navigate = useNavigate()
  const { data } = useContext(GlobalContext)
  const [permission, setPermission] = useState('')

  const enablePush = () => {
    Notification.requestPermission().then((status) => {
      setPermission(status)
    })
  }

  const [current, setCurrent] = useState(
    location.pathname === '/' || location.pathname === ''
      ? '/admin/events'
      : location.pathname
  )

  const logOut = () => {
    deleteRequest('admin/logout', {}, () => {
      window.location.href = '/admin/login'
    })
  }

  const ALL_VIEWS = {
    slots: ['admin', 'supervisor'],
    boxes: ['admin', 'collectivity', 'setup', 'supervisor'],
    owners: ['admin', 'setup'],
    alerts: ['admin', 'supervisor'],
    events: ['admin', 'setup', 'supervisor'],
    users: ['admin', 'setup']
  }

  const item = (key) => ({ key: `/admin/${key}`, label: (<Link to={`/admin/${key}`}>{t(`Admin.Menu.${key}`)}</Link>) })

  const displayMenu = () => {
    const items = []
    for (const key in ALL_VIEWS) {
      if (ALL_VIEWS[key].includes(data.user.roles[0])) {
        items.push(item(key))
      }
    }

    items.push({
      key: 'admin/notification',
      label: (
        <div>
          {/* {permission === 'granted' && <p>{t('Admin.EventList.notification.activated')}</p>} */}
          {permission !== 'granted' && <Button onClick={enablePush}>{t('Admin.EventList.notification.button')}</Button>}
        </div>)
    })

    return (
      <Menu className="admin-menu" style={{ fontSize: '24px', minWidth: '800px', backgroundColor: Color.white, color: Color.dark_blue }}
        onClick={(e) => setCurrent(e.key)}
        selectedKeys={[current]}
        mode='horizontal'
        items={items} />
    )
  }

  return (
    <Layout>
      <Header style={{ display: 'flex', padding: 0, backgroundColor: Color.white, color: Color.dark_blue, height: '90px', alignItems: 'center' }}>
        <img onClick={() => navigate('/admin')}
          style={{ cursor: 'pointer', margin: '13px 20px 0 20px' }}
          src="/images/logo.png" alt='Logo' height={60} />
        {
          data?.user?.roles && displayMenu()
        }
        <div style={{ marginLeft: 'auto', marginRight: '2em' }}>
          <Link onClick={logOut}>Déconnexion</Link>
        </div>
      </Header>
      <Content style={{ margin: '20px', marginTop: 0 }}>
        <EnsureLogin>{children || <Outlet />}</EnsureLogin>
      </Content>
    </Layout>
  )
}
