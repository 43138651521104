import React, { useContext } from 'react'
import { Form, Input } from 'antd'
import GlobalContext from '../GlobalContext'
import { t } from 'i18next'
import TextArea from 'antd/es/input/TextArea'

export default function EventForm ({ form, onFinish, slotId, boxId }) {
  const { data } = useContext(GlobalContext)

  const initialValues = {
    slot_id: slotId,
    box_id: boxId,
    created_by_id: data.user.id
  }

  return (
    <Form
      id="EventFormAdmin"
      form={form}
      onFinish={onFinish}
      initialValues={initialValues}
      size='small'
    >
      <Form.Item name='slot_id' hidden>
        <Input />
      </Form.Item>
      <Form.Item name='box_id' hidden>
        <Input />
      </Form.Item>
      <Form.Item name='created_by_id' hidden>
        <Input />
      </Form.Item>
      <Form.Item name='title' label={t('Admin.Event.attributes.title')}
        rules={[{ required: true, message: t('rules.required') }]}>
        <Input />
      </Form.Item>
      <Form.Item name='asana_url' label={t('Admin.Event.attributes.asanaUrl')}>
        <Input />
      </Form.Item>
      <Form.Item name='description' label={t('Admin.Event.attributes.description')}
        rules={[{ required: true, message: t('rules.required') }]}>
        <TextArea />
      </Form.Item>
    </Form>
  )
}
