import React from 'react'
import { Color } from '../Common/Color'
import { PhoneFilled } from '@ant-design/icons'
import { Header } from 'antd/es/layout/layout'
import { useNavigate } from 'react-router'
import Search from './Search/Search'

const PHONE_NUMBER = '04 48 24 01 28'
const PHONE_HREF = 'tel:+33448240128'

export function SearchHeader () {
  return (
    <div className='hide-md' style={styles.search}><Search onHeader={true} /></div>
  )
}

export function AssistanceHeader () {
  return (
    <>
      <div className='hide-md' style={styles.largeAssistanceText}>
        <div style={styles.assistancePhone}>
          <PhoneFilled /> <a style={{ color: 'white' }} href={ PHONE_HREF }>{ PHONE_NUMBER }</a>
        </div>
      </div>
      <div className='show-md' style={styles.smallAssistanceText}>
        <div style={styles.assistancePhone}>
          <PhoneFilled /><a style={{ color: 'white' }} href={ PHONE_HREF }>{ PHONE_NUMBER }</a>
        </div>
      </div>
    </>
  )
}

export default function PublicHeader ({ withSearch = false }) {
  const navigate = useNavigate()
  return (<>
    <Header className={'header'} style={styles.header}>
      <img onClick={() => navigate('/')} style={styles.logo} className="logo" src="/images/logo-lbm-blanc.svg" alt='Logo' width="200px" />
      <div style={styles.boxText} className='hide-lower-1300'>
        <img className='hide-lower-1300' src="/images/box-exterieur.png" title="Image de la Box extérieur" width="200px" />
        Cabinet médical de téléconsultation augmentée
      </div>
      {withSearch && <SearchHeader />}
      <AssistanceHeader />

    </Header>
    <div className='show-md'><Search onHeader={true} /></div>
  </>
  )
}
const styles = {
  header: {
    gap: '10px',
    display: 'flex',
    padding: '1.5em',
    backgroundColor: Color.white,
    color: Color.dark_blue,
    height: '15%',
    alignItems: 'center',
    marginTop: '1em',
    justifyContent: 'space-around'
  },
  logo: {
    cursor: 'pointer',
    marginRight: '1em'
  },
  boxText: {
    width: '20em',
    lineHeight: '17px',
    fontSize: '1.1em',
    display: 'flex'
  },
  search: {
    paddingLeft: '1em',
    display: 'flex'
  },
  largeAssistanceText: {
    lineHeight: '1em',
    textAlign: 'center',
    color: Color.green,
    fontSize: '2em',
    fontWeight: 'bold',
    marginLeft: '1em',
    width: '8em'
  },
  smallAssistanceText: {
    lineHeight: '1em',
    textAlign: 'center',
    color: Color.green,
    fontSize: '1em',
    fontWeight: 'bold',
    marginLeft: '1em'
  },
  assistancePhone: {
    fontSize: '0.7em',
    padding: '0.5em 1em',
    lineHeight: '1em',
    backgroundColor: Color.green,
    color: Color.white,
    borderRadius: '1em'
  },
  menu: {
    '--hover-color': Color.primary,
    '--hover-border-color': Color.primary,
    border: 'solid 2px',
    fontSize: '1.5em',
    borderRadius: '1.5em',
    padding: '0 0.8em 5px 0.8em'
  }
}
