import React from 'react'
import CustTable from '../../Common/CustTable'
import { t } from 'i18next'
import { Button } from 'antd'
import { FormOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

const columns = () => [
  {
    title: t('Admin.UserList.table.email'),
    dataIndex: 'email',
    key: 'email',
    sorter: (a, b) => a.email.localeCompare(b.email)
  },
  {
    title: t('Admin.UserList.table.firstName'),
    dataIndex: 'first_name',
    key: 'firstName',
    width: 120,
    sorter: (a, b) => a.first_name.localeCompare(b.first_name)
  },
  {
    title: t('Admin.UserList.table.lastName'),
    dataIndex: 'last_name',
    key: 'lastName',
    sorter: (a, b) => a.last_name.localeCompare(b.last_name)
  },
  {
    width: 90,
    title: t('Admin.UserList.table.roles'),
    dataIndex: 'roles',
    key: 'roles',
    sorter: (a, b) => a.roles.localeCompare(b.roles)
  },
  {
    width: 50,
    key: 'action',
    render: (_, o) => <Link to={`/admin/users/${o.id}`}><Button shape="circle" icon={<FormOutlined />} /></Link>
  }
]

export default function UsersList ({ users }) {
  return (
    <CustTable
      height={'calc(100vh - 230px)'}
      initColumns={columns()}
      dataSource={users}
    />
  )
}
