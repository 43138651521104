import React, { useState } from 'react'
import { AutoComplete, Button, Form, Input } from 'antd'
import { Trans, useTranslation } from 'react-i18next'
import { AimOutlined } from '@ant-design/icons'
import { get } from '../../Common/api'
import { Color } from '../../Common/Color'
import { useForm } from 'antd/es/form/Form'
import { useNavigate } from 'react-router'

const highlight = (text, highlight) => {
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'))
  return <span> {parts.map((part, i) =>
    <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { color: Color.red } : {}}>
      {part}
    </span>)
  } </span>
}

const renderItem = (e, search) => ({
  value: `${e.normalized_name} ${e.zip_code}`,
  key: e.id,
  extra: e,
  label: (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      {highlight(e.normalized_name, search)}
      <span> {highlight(e.zip_code, search)} </span>
    </div>
  )
})

export default function Search ({ onHeader = false }) {
  const { t } = useTranslation()
  const [options, setOptions] = useState([])
  const [selectedOption, setSelectedOption] = useState()
  const [form] = useForm()
  const navigate = useNavigate()

  const onFinish = () => {
    if (selectedOption) {
      navigate({
        pathname: '/results',
        search: `?lat=${selectedOption.lat}&long=${selectedOption.long}`
      })
    }
  }

  const handleSearch = (search, latitude, longitude, callback) => {
    get('public/postal_places', { search, lat: latitude || '', long: longitude || '' }, (json) => {
      const newOptions = json.map((e) => renderItem(e, search))
      setOptions(newOptions)
      if (callback) {
        callback(json)
      }
    })
  }

  const geoLoc = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        handleSearch('', position.coords.latitude, position.coords.longitude, (json) => {
          form.setFieldsValue({ search: json[0].normalized_name })
          setOptions([])
          setSelectedOption(json[0])
        })
      })
    }
  }

  const onSelect = (value, option) => {
    setSelectedOption(option.extra)
  }
  const cssSearch = (onHeader) ? { paddingLeft: '1em' } : { borderRadius: '2em', height: '200px', padding: '4em', minWidth: '400px', margin: 'auto', marginTop: '100px', backgroundColor: Color.white }
  const cssLabel = (onHeader) ? { fontSize: '20px', lineHeight: '40px' } : { fontSize: '24px', marginBottom: '10px' }

  return (
  <div style={cssSearch} >
    <div style={cssLabel}>
      <Trans i18nKey="Public.Search.title" />
    </div>
    <Form
      form={form}
      name="basic"
      onFinish={onFinish}
      autoComplete="off"
      layout="inline"
    >
      <Form.Item
        style={{ width: '200px' }}
        name="search"
        rules={[{ required: true, message: t('Public.Search.required') }]}
      >
        <AutoComplete
          dropdownMatchSelectWidth={350}
          options={options}
          onSearch={handleSearch}
          onSelect={(value, option) => onSelect(value, option)}
        >
          <Input autoFocus={true} placeholder={t('Public.Search.placeholder')} addonAfter={<AimOutlined onClick={geoLoc} />} />
        </AutoComplete>
      </Form.Item>
      <Form.Item >
        <Button type="primary" htmlType="submit">
          {t('Public.Search.submit')}
        </Button>
      </Form.Item>
    </Form>
  </div>
  )
}
